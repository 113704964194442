#promotion {
  .banner-container {
    position: relative;
    text-align: center;
    .banner-image {
      width: 100%;
    }
  }
  h3 {
    margin-bottom: 28px;
    text-align: center;
    font-size: 28px;
  }
  .content-box {
    .img {
      border-bottom: 2px solid $primary;
    }
    .desc-text {
      color: $primary;
      text-align: center;
      font-size: 15px;
      margin-bottom: 30px;
    }
    .image-dash {
      margin-bottom: 30px;
    }
    .bottom-wrapper {
      padding: 30px 30px 10px 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      .red_text {
        color: $red;
      }
    }
    .button-wrapper {
      padding-bottom: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      @include rtl-sass-prop(padding-left, padding-right, 80px);
      .btn-red {
        padding: 15px;
        min-width: 200px;
      }
      .image-Wrapper {
        margin-top: 20px;
        @include rtl-sass-prop-sd(transform, scaleX(-1));
      }
    }
  }
}

@media (max-width: 500px) {
  #promotion {
    .content-box {
      .bottom-wrapper {
        padding: 10px;
      }
      .button-wrapper {
        padding-left: 65px;
        @include rtl-sass-prop(padding-left, padding-right, 65px);
        .btn-red {
          padding: 10px;
          font-size: 12px;
        }
      }
    }
  }
}
